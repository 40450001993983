<template>
  <div class="home text-center">
    <h1>Business screener</h1>
    <p>Описание системы</p>
    <router-link to="/reg" class="btn btn-success">Попробовать</router-link>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
