<template>
  <div class="text-center">
    <h1>Регистрация</h1>
    <input type="email" v-model="mail" placeholder="name@gmail.com" class="form-control">
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      mail: ''
    }
  }
}
</script>

<style scoped>
.form-control {
  width: 20em;
  margin: 0 auto;
}
</style>