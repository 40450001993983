<template>
  <div>
    <div class="row mb-2">
      <div class="col-2 text-end">e-mail</div>
      <div class="col-10 text-start"><input type="email" placeholder="name@gmail.com"></div>
    </div>
    <div class="row mb-2">
      <div class="col-2 text-end">Пароль</div>
      <div class="col-10 text-start"><input type="password" placeholder="********"></div>
    </div>
    <div class="row">
      <div class="col-2 text-end"></div>
      <div class="col-10 text-start"><input type="submit" value="Войти" class="btn btn-success"></div>
    </div>
  </div>
</template>

<script>
export default {
name: "Login"
}
</script>

<style scoped>

</style>