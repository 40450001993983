<template>
  <div>
    <div class="row">
      <div class="col-2 text-end">
        <p>Тема</p>
      </div>
      <div class="col-10 text-start">
        <p><label><input type="radio" name="theme" value="default" v-model="theme"> Стандартная</label></p>
        <p><label><input type="radio" name="theme" value="dark" v-model="theme"> Темная</label></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Settings",
  computed: {
    theme: {
      get () {
        return this.$store.getters['settings/theme'];
      },
      set (value) {
        this.$store.commit('settings/setTheme', value);
      }
    }
  }
}
</script>

<style scoped>

</style>