import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import settings from "@/store/modules/settings";

export default new Vuex.Store({
  state: {
    session_ley: localStorage.getItem('session_ley')??false
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    settings
  }
})
