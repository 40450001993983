export default {
    namespaced: true,
    state: {
        theme: localStorage.getItem('theme')??'default',
    },
    getters: {
        theme(state){
            return state.theme;
        },
    },
    mutations: {
        setTheme(state, theme) {
            state.theme = theme;
            localStorage.setItem('theme', theme);
        }
    },
    actions: {
        setTheme(store, theme) {
            store.commit('setTheme', theme);
        }
    }
};